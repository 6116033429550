import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  Subject,
  throwError,
} from 'rxjs';
import { environment } from 'src/environments/environment';

export interface TabInfo {
  tab: string;
  index: number;
}

interface FileUpload {
  fileUpload: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  buttonHeader = 'create';
  userCurrentTabSubject: BehaviorSubject<TabInfo> = new BehaviorSubject({
    tab: 'MTT',
    index: 0,
  });

  constructor(private http: HttpClient) {}

  private _headerResultsUpdated$: Subject<void> = new Subject<void>();

  /****************************************
   *  Get Result from Header
   ****************************************/
  get headerResultsUpdated$(): Observable<void> {
    return this._headerResultsUpdated$.asObservable();
  }

  /****************************************
   *  Header Button Change
   *  it will set the value in header of button
   ****************************************/
  set headerButtonChange(val: string) {
    this.buttonHeader = val;
    this._headerResultsUpdated$.next();
  }

  /****************************************
   *  Button Change
   *  It will change button header
   ****************************************/
  get buttonChange(): string {
    return this.buttonHeader;
  }

  /****************************************
   * Get User By Id
   ****************************************/
  getUserById(userId: string): Observable<any> {
    return this.http.get(
      `${environment.services.rhemaServer}/users/getUserById/${userId}`
    );
  }

  getUserByEmail(email: any): Observable<any> {
    return this.http.post(
      `${environment.services.rhemaServer}/users/getUserByEmail`,
      { email }
    );
  }

  getTimeLineExtendUser(id: any): Observable<any> {
    return this.http.post(
      `${environment.services.rhemaServer}/timeLine/getTimeLineExtendUser`,
      { id }
    );
  }

  approvalTimeLineExtend(data: any): Observable<any> {
    return this.http.post(
      `${environment.services.rhemaServer}/timeLine/approvalTimeLineExtend`,
      data
    );
  }

  /****************************************
   * Get User By Id
   ****************************************/
  updateUserData(userData: any, userId: string): Observable<any> {
    return this.http.put(
      `${environment.services.rhemaServer}/users/updateUser/${userId}`,
      userData
    );
  }

  /****************************************
   *  Admin Login Function
   ****************************************/
  adminLogin(data: any): Observable<any> {
    const url = `${environment.services.rhemaServer}/auth/native/login`;
    return this.http.post(url, data);
  }

  /****************************************
   *  Upload File
   ****************************************/
  uploadFile(formData: FormData): Observable<FileUpload> {
    return this.http
      .post(
        `${environment.services.rhemaServer}/v2/file/upload-files`,
        formData
      )
      .pipe(map((receivedData: FileUpload | any) => <FileUpload>receivedData));
  }

  /****************************************
   *  Get user by the language
   ****************************************/
  getUsersByLanguage(language: string): Observable<any> {
    return this.http.get(
      `${environment.services.rhemaServer}/users/getUserByLanguage/${language}`
    );
  }

  /****************************************
   *  Get User By Role
   ****************************************/
  getUsersByRole(role: string): Observable<any> {
    return this.http.get(
      `${environment.services.rhemaServer}/users/getUsersByType/${role}`
    );
  }

  /****************************************
   *  Set Tab Info
   ****************************************/
  setTabInfo(currentTab: any, currentIndex: any) {
    const tabInfo = { tab: currentTab, index: currentIndex };
    this.userCurrentTabSubject.next(tabInfo);
  }

  /****************************************
   * Create User
   ****************************************/
  createUser(data: any): Observable<HttpEvent<{}>> {
    const req = new HttpRequest(
      'POST',
      `${environment.services.rhemaServer}/users`,
      data,
      {
        reportProgress: true,
        responseType: 'text',
      }
    );
    return this.http.request(req);
  }

  /****************************************
   *  Delete user
   ****************************************/
  deleteUser(userId: string): Observable<any> {
    return this.http.delete(
      `${environment.services.rhemaServer}/users/deleteUser/${userId}`
    );
  }

  /****************************************
   * Change User Password
   ****************************************/
  changeUserPassword(data: any): Observable<any> {
    return this.http.post(
      `${environment.services.rhemaServer}/users/admin-changePassword`,
      data
    );
  }

  /****************************************
   * Get all list of user by specific role
   ****************************************/
  getUserByRole(role: string): Observable<any> {
    return this.http.get(
      `${environment.services.rhemaServer}/users/getUsersByType/${role}`
    );
  }

  /****************************************
   * Error Handling
   ****************************************/
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(error);
      console.log(`${operation} failed : ${error.message}`);
      return of(result as T);
    };
  }

  getUserDetailById(userId: string): Observable<any> {
    return this.http.get(
      `${environment.services.rhemaServer}/users/getUserDetailById/${userId}`
    );
  }
  getClonedVoices(): Observable<any> {
    return this.http.get(
      `${environment.services.rhemaServer}/voice-cloning/listVoices`
    );
  }

  uploadVoiceCloneFiles(formData: FormData): Observable<FileUpload> {
    return this.http
      .post(
        `${environment.services.rhemaServer}/voice-cloning/cloneVoice`,
        formData
      )
      .pipe(map((receivedData: FileUpload | any) => <FileUpload>receivedData));
  }
}
